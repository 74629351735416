//  import logo from './logo.svg';
//  import './index.css';
//  import './App.css';
import Projects from './Projects.js';
import ProjectDetails from './ProjectDetails.js';
import SkillSets from './SkillSets.js';
import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs';
import { useState, useEffect } from 'react';

function PortfolioPage() {

	const [ theme, setTheme ] = useState(true);
	const [ project, setProject ] = useState();



	useEffect(() => {
		//  document.documentElement.classList.toggle('dark', theme);
		if(theme) {
			document.documentElement.classList.add('dark')
		} else {
			document.documentElement.classList.remove('dark')
		}
		//  document.getElementsByTagName("html")[0].setAttribute("className","dark")
	},[theme]);

	const ThemeToggleIcon = theme
		? BsFillSunFill
		: BsFillMoonFill

	const Home = (
		<div
			className='grid grid-cols-12'
		>
			<div className='sm:col-span-3 col-span-12'>
				<SkillSets />
			</div>
			<div className='sm:col-span-9 col-span-12'>
				<Projects setProject={setProject}/>
			</div>
		</div>
	)
	return (
		<div
			className={`
          h-full
          w-screen
          bg-gradient-to-b
          dark:from-black
          dark:via-gray-900
          dark:to-gray-800
          dark:text-white
          from-white
          via-gray-100
          to-gray-50
          text-black
          text-xl
          font-sans
          flex
          items-center
          justify-center
      `}
		>
			<div className='w-11/12'>
				<div className={`
            border-b
            border-1
            dark:border-gray-50
            border-gray-900
            py-5
            font-semibold
            flex
            justify-center
        `}>
					previous work
					{/*
						<ThemeToggleIcon
							className={`
	              cursor-pointer
	              hover:text-green-500
	              m-1
	            `}
							onClick={() => setTheme(!theme)}
						/>
					*/}



				</div>
				{
					project
						? <ProjectDetails project={project} setProject={setProject} />
						: Home
				}
			</div>

		</div>

	);
}

export default PortfolioPage;
