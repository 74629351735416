import ProcessScopeImage from '../../images/process-scope.png'
import ProcessBuildImage from '../../images/process-build.png'
function ProcessPage() {

	return (
		<div
			className={`
          h-full
          w-screen          
          text-black
          text-xl
          font-sans
          flex
          items-center
          justify-center
      `}
		>
			<div className='w-6/12'>
				<div className={`
            border-b
            border-1
            dark:border-gray-50
            border-gray-100
            py-5
            font-bold
            text-6xl
            text-green-400
            flex
            justify-center
        `}>
					software development with lazylabs
				</div>
				<div className='my-5'>
					<h1 className='text-6xl font-bold'>Scoping Requirements</h1>
					<p className='font-medium leading-relaxed my-6'>Figuring out what to build is an import part of the process and is deceptively more challenging than initially think.</p>
				</div>

				<div className='my-5'>
					<h2 className='text-4xl font-bold my-5'>why? what we want to build can change…and changes all the time</h2>
					<p className='font-medium leading-relaxed my-6'>This is because figuring out what to build changes throughout a project.  It can change once you learn more, once you built a small piece and interact with your application , it can change because you had more time to think.  Engineers know this from product owners and clients as the dreaded scope creep.</p>
					<p className='font-medium leading-relaxed my-6'>It is our job to help you figure out what you want and have a process for handling updating your app you fine tune what you would like to build.</p>
				</div>

				<div className='my-5'>
					<h2 className='text-4xl font-bold my-5'>managing scope</h2>
					<p className='font-medium leading-relaxed my-6'>
						Focusing on the right things, and the right level of detail:
					</p>
					<ul className='list-disc ml-5'>
						<li className='font-medium leading-relaxed'>
							fatmarker sketches vs high fidelity mockups vs figma
						</li>
						<li className='font-medium leading-relaxed'>
							The right level of detail
						</li>
						<li className='font-medium leading-relaxed'>
							less is more
						</li>
						<li className='font-medium leading-relaxed'>
							too much detail on the wrong things leads to missing the things that matter
						</li>
					</ul>
				</div>
				<div className='my-5'>
					<h2 className='text-6xl font-bold my-5'>tools</h2>
					<p className='font-medium leading-relaxed my-6'>
						Use the right tool, for the right job.
					</p>

					<h2 className='text-4xl font-bold my-5'>meetings</h2>
					<p className='font-medium leading-relaxed my-6'>
						Yes, we consider meetings a tool and like all tools should only be used when appropriate.  Initially meetings are good for learning requirements and as inputs for scoping work.  Then meetings are usefully for reviewing documented scoped work, live review hard to debug issues.  Of course meetings can be used for other things like:
					</p>
					<ul className='list-disc ml-5'>
						<li className='font-medium leading-relaxed'>
							talking through bugs as soon as they are found
						</li>
						<li className='font-medium leading-relaxed'>
							live review of <em>all</em> issues
						</li>
						<li className='font-medium leading-relaxed'>
							discussing new ideas for features (or changing scope) everytime they come up
						</li>
						<li className='font-medium leading-relaxed'>
							too much detail on the wrong things leads to missing the things that matter
						</li>
					</ul>
					<p className='font-medium leading-relaxed my-6'>The problem with over using meetings is that it 1. interrupts flow whichs slows down development 2. there are better ways to capture simple issues, write them down!</p>


					<h2 className='text-4xl font-bold my-5'>pen and paper</h2>
					<p className='font-medium leading-relaxed my-6'>
						Yes, pen and paper! We use pen (actually markers) and paper to capture fat marker sketches of key flows of the user behavior.  This is done intentionally to focus only on the high level functionality that matters.  This is not the stage to worry about “pixel perfect” designs.  Pixel perfect designs and high fidelity mockups or what we consider non functional requirements that are better addressed with other tools and a different stage of the process.
					</p>

					<h2 className='text-4xl font-bold my-5'>figma diagrams</h2>
					<p className='font-medium leading-relaxed my-6'>
						Figma is great for capturing storyboards sthat show sequence of events of an application.  Each step of the sequence can varyfrom high level fat marker sketch to wireframes to high fidelity mockups.
					</p>

					<h2 className='text-4xl font-bold my-5'>notion</h2>
					<p className='font-medium leading-relaxed my-6'>
						We use Notion to write things down! Notion is great for capturing requirements  including fat mark sketches,  figma diagrams, and enough of a write up to understand how a feature works.
					</p>
					<p className='font-medium leading-relaxed my-6'>
						We use notion to document scope before starting work and to capture feedback once development work has started.  This includes documenting simple bug fixes, capture details on hard to debug issues.  By writing things down in notion documents, we have something to point to, and we don’t need to have meetings to remember how something works or what we agreed to.
					</p>

					<h2 className='text-4xl font-bold my-5'>loom</h2>
					<p className='font-medium leading-relaxed my-6'>
						Loom is This is a great tool for capturing brief videos showing how a new feature should work or how existing functionality broken.  This can be a good option when its wuicker to record a 1-2 minute video instead writing it down or having a meeting
					</p>
				</div>
				<div className='my-5'>
					<h2 className='text-6xl font-bold my-5'>process</h2>
					<p className='font-medium leading-relaxed my-6'>
						here's how we put it all together:
					</p>
					<h3 className='text-4xl font-bold my-5'>scope requirements</h3>
					<p className='font-medium leading-relaxed my-6'>
						The first stage is to determine the scope of what we are trying to build. What's included?  <i>what's <b>not</b> included</i>?
					</p>
					<img src={ProcessScopeImage} alt='diagram showing the scoping stage of the process' />
					<p className='font-medium leading-relaxed mb-6'>
						The goal of this stage is to get from conversations about what we want to build to specs detailed enough that
						any developer can reasonably understand and go off and starting building.  If for any reason yu would not like to move forward with lazy labs, then this is also a good breaking
						point to hand off specs to other developers.
					</p>

					<h3 className='text-4xl font-bold my-5'>build, test, and deploy</h3>
					<p className='font-medium leading-relaxed mb-6'>
						Once we have the work scoped, the next stage is to actually build, test, and iterate!
					</p>
					<img src={ProcessBuildImage} alt='diagram showing the build stage of the process' />
					<p className='font-medium leading-relaxed my-6'>
						The goal of this stage build your application one piece at a time.  Your time and budget is not unlimited,
						so we cannot afford endless revisions.  However, by scoping the work well in the previous stage
						allows for revisions against the agreed upon scope.  By working one piece at a time,
						we give ourselves the flexibility to determine how to reprioritize scope if changes are needed
						before the end of the project.
					</p>
				</div>
			</div>

		</div>

	);
}

export default ProcessPage;
