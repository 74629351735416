
export default function Skills(props) {

  const {
    type,
    skills,
    Icon
  } = props;

  if (!skills) {
    return null;
  }

  const Pills = skills.map( (skill, skillIndex) => {
    return (
      <div
        className={`
          py-1
          px-2
          m-1
          text-sm
          font-bold
          rounded-lg
          flex
          items-center
          justify-start
        `}
        key={skillIndex}
      >
        {skill}
      </div>
    )
  });





  return (
    <>
      <div className='text-base font-semibold my-5 flex justify-start items-center'>

        <span
          className={`
            flex
            items-center
            justify-center
          `}
        >
          {type}&nbsp;<Icon className='text-md'/>
        </span>
      </div>
      <div className='grid grid-cols-2 gap-1 ml-2'>
        {Pills}
      </div>
    </>
  );
}
