//  import { usePostHog } from 'posthog-js/react'
function HomePage() {
  //  const posthog = usePostHog()

  return (
    <div className={`
        h-screen
        w-screen
        flex justify-center
        items-center
        text-6xl
        text-gray-200
        font-bold
      `}
    >
      <div>
        <p>build with</p>
        <p>standards</p>
        <p>protocols</p>
        <p>web3</p>
        <h1 className="text-green-300">
        lazy labs
        </h1>
      </div>

    </div>
  );
}

export default HomePage;
