import hl7 from '../../images/hl7.png';
import mailmask from '../../images/mailmask.png';
import mailbridge from '../../images/mailmask.png';

import measurehub from '../../images/measurehub.png';
import ndu from '../../images/ndu.png';
import openmrs from '../../images/openmrs.png';
import ProjectSkills from './ProjectSkills';

export default function ProjectDetails(props) {

  const Images = {
    hl7,
    mailmask,
    mailbridge,
    measurehub,
    ndu,
    openmrs,
  };

  const {
    project: {
      key,
      name,
      description,
      img,
      skills,
      languages,
      frameworks,
      technologies,
      databases,
      duties,
      web3,
      url,
    },
    setProject,
  } = props;


  const Link = (
    <a
      href={url}
      target='_blank'
      rel='noreferrer'
      className={`
        text-green-500
        hover:text-green-600
        hover:underline
        font-semibold
      `}
    >
      {url}
    </a>
  );

  const Duties = duties.map( (duty, index) => {
    return (
      <li
        key={index}
        className={`
            ml-4
            font-medium
        `}
      >
        {duty}
      </li>
    );
  })



  return (
    <div className='mt-5 min-h-screen h-full leading-10'>
      <div className='grid sm:grid-cols-12 sm:gap-12 grid-cols-1'>
        <div className='sm:col-span-4'>
          <img
            src={Images[key]}
            alt={`${name} screenshot`}
            className='rounded-lg shadow-lg'
          />
        </div>
        <div className={`
          sm:col-span-8
        `}>
          <div
            className={`
              dark:bg-gray-800
              bg-gray-100
              opacity-90
              p-5
              rounded-t-lg
              `}
          >
            <div className="flex justify-between items-center mb-5">
              <span
                className="font-black text-4xl"
              >
                {name}
              </span>
              <span
                className={`
                  hover:underline
                  text-green-500
                  font-bold
                  hover:text-green-600
                  cursor-pointer
                `}
                onClick={() => setProject()}
              >
                back
              </span>
            </div>
            {Link}
            <div className='font-medium'>{description}</div>
          </div>
          <div className={`
            dark:bg-gray-800
            bg-gray-100
            opacity-90
            my-2
            p-5
            `}>
            <span
              className={`
                font-black
              `}
            >
              what I did
            </span>
            {Duties}
        </div>
        <div className={`
          dark:bg-gray-800
          bg-gray-100
          opacity-90
          my-2
          p-5
          rounded-b-lg
          `}>
          <span
            className={`
              font-black
            `}
          >
            what I used
          </span>
          <ProjectSkills type='Skills' skills={skills} />
          <ProjectSkills type='Languages' skills={languages} />
          <ProjectSkills type='Frameworks' skills={frameworks} />
          <ProjectSkills type='Databases' skills={databases} />
          <ProjectSkills type='Web3 Technologies' skills={web3} />
          <ProjectSkills type='Other Technologies' skills={technologies} />

        </div>
      </div>
    </div>
    </div>
  );
}
