import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './pages/HomePage'
import Portfolio from "./pages/portfolio/Portfolio";
import Process from './pages/process/Process'
import reportWebVitals from './reportWebVitals';
import { PostHogProvider} from 'posthog-js/react'

//  posthog.init(process.env.REACT_APP_POSTHOG_KEY), { api_host: 'https://app.posthog.com' });
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

console.log({ KEY: process.env.REACT_APP_POSTHOG_KEY })
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_POSTHOG_KEY}
      options={options}
    >
      <BrowserRouter>
       <Routes>
         <Route path="/" element={<HomePage />}>
           <Route index element={<HomePage />} />
         </Route>
         <Route path="/portfolio" element={<Portfolio />} />
         <Route path="/process" element={<Process />} />
       </Routes>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
